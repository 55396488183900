<template>
  <div>
    <!-- 编辑记录-表具信息 -->
    <div class="content1">
      <div class="all">
        <div class="table" >
          <div style="display:flex;margin-top:20px">
            <el-button  class="SearchBtn" size="small" @click="backtrack">返回</el-button>
          </div>
          <!--数据表格-->
          <div>
            <el-form class="form_box">
            <div class="front_box">
              
              <div class="particulars">
                <span class="img"></span>
                <span class="titles">编辑前数据</span>
              </div>
                <div class="box_info">
                  <el-row>
                    <el-col :span="12">
                      <el-form-item >
                        <span slot="label"><span style="color: #303133;font-size: 16px;font-weight: 900;">表具信息</span></span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <el-form-item label="表编号：" prop="positionName" label-width='120px'>
                        {{ oldData.sheetSteel }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="燃气表类型：" prop="positionName" label-width='120px'>
                        {{ oldData.tableTypeName }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="表向：" prop="positionName" label-width='120px'>
                        {{ oldData.tableDirectionName }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="燃气表厂家：" prop="positionName" label-width='120px'>
                        {{ oldData.tableVenderName }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="表封号：" prop="positionName" label-width='120px'>
                        {{ oldData.tableTitles }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="开户日期：" prop="positionName" label-width='120px'>
                        {{ oldData.openDate }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="所接炉具：" prop="positionName" label-width='120px'>
                        {{ oldData.receiveStove }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="对接系统：" prop="positionName" label-width='120px'>
                        {{ oldData.tableSystem==1?'卡表系统':'营收系统' }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="识别号：" prop="positionName" label-width='120px'>
                        {{ oldData.identifyNumber }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="备注：" prop="positionName" label-width='120px'>
                        {{ oldData.remark }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="启用状态：" prop="positionName" label-width='120px'>
                        {{ oldData.generalStatus==0?'禁用':'启用' }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="front_box">
              
              <div class="particulars">
                <span class="img" style="border: 1px solid #E93838"></span>
                <span class="titles">编辑后数据</span>
              </div>
                <div class="box_info">
                  <el-row>
                    <el-col :span="12">
                      <el-form-item >
                        <span slot="label"><span style="color: #303133;font-size: 16px;font-weight: 900;">表具信息</span></span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <el-form-item label="表编号：" prop="positionName" label-width='120px'>
                        <span :class="newData.sheetSteel==oldData.sheetSteel?'':'red'">{{ newData.sheetSteel }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="燃气表类型：" prop="positionName" label-width='120px'>
                        <span :class="newData.tableTypeName==oldData.tableTypeName?'':'red'">{{ newData.tableTypeName }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="表向：" prop="positionName" label-width='120px'>
                        <span :class="newData.tableDirectionName==oldData.tableDirectionName?'':'red'">{{ newData.tableDirectionName }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="燃气表厂家：" prop="positionName" label-width='120px'>
                        <span :class="newData.tableVenderName==oldData.tableVenderName?'':'red'">{{ newData.tableVenderName }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="表封号：" prop="positionName" label-width='120px'>
                        <span :class="newData.tableTitles==oldData.tableTitles?'':'red'">{{ newData.tableTitles }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="开户日期：" prop="positionName" label-width='120px'>
                        <span :class="newData.openDate==oldData.openDate?'':'red'">{{ newData.openDate }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="所接炉具：" prop="positionName" label-width='120px'>
                        <span :class="newData.receiveStove==oldData.receiveStove?'':'red'">{{ newData.receiveStove }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="对接系统：" prop="positionName" label-width='120px'>
                        <span :class="newData.tableSystem==oldData.tableSystem?'':'red'"> {{ newData.tableSystem==1?'卡表系统':'营收系统' }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="识别号：" prop="positionName" label-width='120px'>
                        <span :class="newData.identifyNumber==oldData.identifyNumber?'':'red'">{{ newData.identifyNumber }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="备注：" prop="positionName" label-width='120px'>
                        <span :class="newData.remark==oldData.remark?'':'red'">{{ newData.remark }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="启用状态：" prop="positionName" label-width='120px'>
                        <span :class="newData.generalStatus==oldData.generalStatus?'':'red'">{{ newData.generalStatus==0?'禁用':'启用' }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>


<script>
import {tabkeWithUpdateDetails} from '../../../RequestPort/intranet/index'
export default {
  name: "lockout",
  data(){
    return{
      newData:{

      },
      oldData:{

      }
    }
  },
  mounted() {
    this.loadList()
  },
  methods:{
    /**
     * 返回
     */
    backtrack(){
      this.$router.back(-1)
    },
    loadList(){
      tabkeWithUpdateDetails({recordId:this.$route.query.id}).then(res=>{
        this.newData = res.data.newData
        this.oldData = res.data.oldData
      })
    }
  }
}
</script>

<style lang="less" scoped>
.red{
  color: red;
}
.SearchBtn{
  margin-left: 10px;
}
.table {
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
  background-color: #ffffff;
  height: calc(100vh - 210px)
}
.particulars {
  display: flex;
  flex-direction: row;
  align-items:center;
  height: 40px;
  position: relative;
  .img {
    border: 1px solid #3f74d5;
    height: 16px;
    margin-right: 9px;
  }
  // 标题文字
  .titles{
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight:900;
    color: #303133;
  }
}
.el-form-item{
  margin: 0;
  ::v-deep.el-form-item__label{
        text-align: left;
  }
}
.form_box{
  display: flex;
  flex-direction: row;
}
// 数据前
.front_box{
  width: 778px;
  margin: 20px 30px;
  .box_info{
    padding-left: 29px;
  }
}
</style>